<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">现有安消防系统硬件设施信息信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="安消防系统名称" prop="xfxtmc">
                    <el-input
                        v-model="formdata.saveJson.xfxtmc"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用途分类">
                    <el-input
                        v-model="formdata.saveJson.ytfl"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="投资总额" prop="tzje">
                    <el-input
                        v-model="formdata.saveJson.tzje"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">万元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="中央财政经费" prop="zyczjf">
                    <el-input
                        v-model="formdata.saveJson.zyczjf"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">万元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="监控探头数量" prop="jkttsl">
                    <el-input
                        v-model="formdata.saveJson.jkttsl"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">个</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="建成时间">
                    <el-date-picker
                        v-model="formdata.saveJson.jcsj"
                        type="month"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="系统构成描述" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.xtgcms"
                        type="textarea"
                        :maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    basicType="document"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "assembly_resolutions",
    components: {},
    mixins: [infoMixin],
    data() {
        let checkSL = (rule, value, callback) => {
            let reg = /^[0-9]+$/;
            if (value === "" || !value) {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else if (!reg.test(value)) {
                callback(new Error("请输入正整数"));
            } else {
                callback();
            }
        };
        let checkNum = (rule, value, callback) => {
            if (value === "") {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                xfxtmc: [
                    {
                        required: true,
                        message: "请输入消防系统名称",
                        trigger: "blur",
                    },
                ],
                tzje: [{ validator: checkNum, trigger: "change" }],
                zyczjf: [{ validator: checkNum, trigger: "change" }],
                jkttsl: [{ validator: checkSL, trigger: "change" }],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    xfxtmc: "",
                    ytfl: "",
                    tzje: "",
                    zyczjf: "",
                    jkttsl: "",
                    jcsj: "",
                    xtgcms: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "25",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped></style>
